const SERVER_URL = 'https://admin.kursyfinansowe.com.pl/api'

export const getCurrentCourses = () => {
    return fetch(`${SERVER_URL}/course?current-only=1`)
        .then(response => response.json())
}

export const signUp = (data) => {
    return fetch(`${SERVER_URL}/signup`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: data,
    })
}
