import React from "react"
import { Field } from "react-final-form"

import FieldContainer from "./Form/FieldContainer"
import Label from "./Form/Label"
import Condition from "./Form/Condition"
import AddressContainer from "./Form/AddressContainer"
import CityContainer from "./Form/CityContainer"
import PostalCodeContainer from "./Form/PostalCodeContainer"
import TextInput from './Form/TextInput'
import * as validate from './Form/validate'

const FormUserGroup = ({ prefix, includeDetails = false, excludePhone = false }) => {
  return (
    <>
        <FieldContainer>
          <Label htmlFor={`${prefix}first_name`}>Imię</Label>
          <Field
            validate={validate.required}
            name={`${prefix}first_name`}
            type="text"
            id={`${prefix}first_name`}
            component={TextInput}
          />
        </FieldContainer>

        <FieldContainer>
          <Label htmlFor={`${prefix}last_name`}>Nazwisko</Label>
          <Field
            validate={validate.required}
            name={`${prefix}last_name`}
            type="text"
            id={`${prefix}last_name`}
            component={TextInput}
          />
        </FieldContainer>

        <FieldContainer>
          <Label htmlFor={`${prefix}email_address`}>Email</Label>
          <Field
            validate={validate.required}
            name={`${prefix}email_address`}
            type="email"
            id={`${prefix}email_address`}
            component={TextInput}
          />
        </FieldContainer>

        { !excludePhone && <FieldContainer>
          <Label htmlFor={`${prefix}phone`}>Numer telefonu</Label>
          <Field
            validate={validate.required}
            name={`${prefix}phone`}
            type="tel"
            id={`${prefix}phone`}
            component={TextInput}
          />
        </FieldContainer> }
        {includeDetails && (
          <Condition when="type" is="personal">
            <>
              <AddressContainer>
                <Label htmlFor={`${prefix}address`}>Adres</Label>
                <Field
                  validate={validate.required}
                  name={`${prefix}address`}
                  type="text"
                  id={`${prefix}address`}
                  component={TextInput}
                />
              </AddressContainer>

              <CityContainer>
                <Label htmlFor={`${prefix}city`}>Miejscowość</Label>
                <Field
                  validate={validate.required}
                  name={`${prefix}city`}
                  type="text"
                  id={`${prefix}city`}
                  component={TextInput}
                />
              </CityContainer>

              <PostalCodeContainer>
                <Label htmlFor={`${prefix}postal_code`}>Kod pocztowy</Label>
                <Field
                  validate={validate.required}
                  name={`${prefix}postal_code`}
                  type="text"
                  id={`${prefix}postal_code`}
                  component={TextInput}
                />
              </PostalCodeContainer>
            </>
          </Condition>
        )}
      </>
  )
}

export default FormUserGroup
