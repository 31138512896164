import React from "react"
import tw, { styled } from "twin.macro"

const Input = styled.input`
  ${tw`mt-1 form-input block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
`

const Error = styled.span`
  ${tw`text-red-600 text-xs`}
`

const TextInput = ({ input, meta, ...props }) => {
  return (
    <>
      <Input {...input} {...props} />
      {meta.error && meta.touched && <Error>{meta.error}</Error>}
    </>
  )
}

export default TextInput
