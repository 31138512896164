import React from "react"
import tw, { styled } from "twin.macro"

const Container = styled.div`
  ${tw`my-10 sm:mt-0`}
`

const FormContainer = styled.div`
  ${tw`md:grid md:grid-cols-3 md:gap-6`}
`

const DescriptionContainer = styled.div`
  ${tw`md:col-span-1`}
`

const DescriptionInnerContainer = styled.div`
  ${tw`px-4 sm:px-0`}
`

const DescriptionContent = styled.p`
  ${tw`mt-1 text-sm leading-5 text-gray-600`}
`

const FormWrapper = styled.div`
  ${tw`mt-5 md:mt-0 md:col-span-2`}
`

const FormInnerContainer = styled.div`
  ${tw`shadow overflow-hidden sm:rounded-md`}
`

const FormInputsContainer = styled.div`
  ${tw`px-4 py-5 bg-white sm:p-6`}
`

const DescriptionHeader = styled.h3`
  ${tw`text-lg font-medium leading-6 text-gray-900`}
`

const FormPanel = ({ cta, description, header, children }) => {
  return (
    <Container>
      <FormContainer>
        <DescriptionContainer>
          <DescriptionInnerContainer>
            <DescriptionHeader>{header}</DescriptionHeader>
            <DescriptionContent>{description}</DescriptionContent>
          </DescriptionInnerContainer>
        </DescriptionContainer>
        <FormWrapper>
          <FormInnerContainer>
            <FormInputsContainer>{children}</FormInputsContainer>
            {cta ? cta : null}
          </FormInnerContainer>
        </FormWrapper>
      </FormContainer>
    </Container>
  )
}

export default FormPanel
