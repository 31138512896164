import React from "react"
import { Form as FinalForm, Field, FormSpy } from "react-final-form"
import tw, { styled } from "twin.macro"
import { FORM_ERROR } from "final-form"
import arrayMutators from "final-form-arrays"
import { FieldArray } from "react-final-form-arrays"
import createDecorator from "final-form-calculate"
import dayjs from "dayjs"

import * as utils from "../utils"
import * as api from "../apiConnection"
import { setName, setEmail, triggerGoal } from "../livechat"
import Layout from "../components/Layout"
import FormPanel from "../components/FormPanel"
import FormInputsInnerContainer from "../components/Form/FormInputsInnerContainer"
import Wrapper from "../components/Form/Wrapper"
import FieldContainer from "../components/Form/FieldContainer"
import Label from "../components/Form/Label"
import AddressContainer from "../components/Form/AddressContainer"
import CityContainer from "../components/Form/CityContainer"
import PostalCodeContainer from "../components/Form/PostalCodeContainer"
import Condition from "../components/Form/Condition"
import TextInput from "../components/Form/TextInput"
import FormUserGroup from "../components/FormUserGroup"
import TextContainer from "../components/TextContainer"
import OkIcon from "../components/OkIcon"
import Seo from "../components/Seo"
import * as validate from "../components/Form/validate"

const AgreementContainer = styled.div`
  ${tw`flex items-start`}
`

const CheckboxContainer = styled.div`
  ${tw`flex items-center h-5`}
`

const AgreementLabelContainer = styled.div`
  ${tw`flex-grow ml-2`}
`

const Select = styled.select`
  ${tw`mt-1 block form-select w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5`}
`

const ButtonContainer = styled.div`
  ${tw`px-4 py-3 bg-gray-50 text-right sm:px-6`}
`

const Button = styled.button`
  ${tw`py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 shadow-sm focus:outline-none focus:shadow-outline-blue active:bg-red-600 transition duration-150 ease-in-out`}
  ${props => props.secondary && tw`bg-blue-700 hover:bg-blue-800`}
  ${props => props.tertiary && tw`bg-gray-400 hover:bg-gray-500 mr-2`}
  ${props => props.pending && tw`bg-red-300 hover:bg-red-300`}
`

const HeroContainer = styled.div`
  ${tw`mx-auto max-w-screen-xl px-4 sm:pt-12 sm:px-6 md:pt-16 lg:pt-10 xl:pt-10 mb-10 mt-0 text-center`}
`

const HeroHeader = styled.h2`
  ${tw`text-xl tracking-tight leading-8 font-extrabold text-gray-900 sm:text-2xl sm:leading-none md:text-4xl`}
`

const AgreementText = styled.p`
  ${tw`block text-sm leading-5 text-gray-500 mb-3`}
`

const AgreementLink = styled.a`
  ${tw`text-blue-700`}
`

const RadiosContainer = styled.div`
  ${tw`flex`}
`

const RadioContainer = styled.div`
  ${tw`flex items-center mb-1 mr-6`}
`

const RadioInput = styled.input`
  ${tw`form-radio h-4 w-4 text-blue-700 transition duration-150 ease-in-out`}
`

const RadioLabel = styled.label`
  ${tw`ml-3`}
`

const RadioLabelText = styled.span`
  ${tw`block text-sm leading-5 font-medium text-gray-700`}
`

const Backoff = styled.div`
  ${tw`bg-gray-100 py-5`}
`

const SubmitError = styled.p`
  ${tw`text-red-600 text-sm leading-5 pb-3`}
`

const SuccessView = styled.div`
  ${tw`mt-5`}
`

const SuccessIconContainer = styled.div`
  ${tw`flex justify-center`}
`

const Error = styled.div`
  ${tw`text-red-600 text-xs ml-3`}
`

const CheckbotInput = ({ input, meta, ...props }) => {
  return (
    <>
      <input {...input} {...props} />
      {meta.error && meta.touched && <Error>{meta.error}</Error>}
    </>
  )
}

const getTermIdFromUrl = () => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    return urlParams.get("termin")
}

const parseStudents = students =>
  students.reduce((acc, student, index) => {
    return {
      ...acc,
      [`signuper${index}[name]`]: student.first_name,
      [`signuper${index}[surname]`]: student.last_name,
      [`signuper${index}[email]`]: student.email_address,
    }
  }, {})

const Form = () => {
  const [courseList, setCourseList] = React.useState(null)
  const [success, setSuccess] = React.useState(false)
  const [initialValues, setInitialValues] = React.useState({
    type: "personal",
    course: null,
    term: null,
    students: [
      {
        first_name: "",
        last_name: "",
        email_address: "",
        phone: "",
      },
    ],
  })
  React.useEffect(() => {
    api.getCurrentCourses().then(courses => {
      if (utils.isBrowser) {
        const termId = getTermIdFromUrl()
        const foundCourse = termId && courses.find(course => course.terms._id === termId) 
        setInitialValues(_initialValues => ({
          ..._initialValues,
          course: foundCourse ? foundCourse._id : courses[0]._id,
          term: (foundCourse && termId) || courses[0].terms._id,
        }))
      } else {
        setInitialValues(_initialValues => ({
          ..._initialValues,
          course: courses[0]._id,
          term: courses[0].terms._id,
        }))
      }
      setCourseList(courses)
    })
  }, [])

  const parsedUniqueCourseList = React.useMemo(() => {
    if (!courseList) {
      return null
    }
    return courseList.reduce((acc, current) => {
      if (acc.map(el => el.id).indexOf(current._id) === -1) {
        return [
          ...acc,
          {
            name: current.name,
            id: current._id,
          },
        ]
      }
      return acc
    }, [])
  }, [courseList])

  const calulator = React.useMemo(() => {
    if (!courseList) {
      return
    }
    return createDecorator({
      field: "course",
      updates: {
        term: course => {
          let providenTerm = null
          if (utils.isBrowser) {
            const termId = getTermIdFromUrl()
            const foundCourse = termId && courseList.find(_course => _course.terms._id === termId)
            providenTerm = foundCourse && foundCourse.terms._id
          }
          return providenTerm || courseList.find(_course => _course._id === course).terms._id
        },
      },
    })
  }, [courseList])

  const submitForm = data => {
    let form = {
      "course-id": `${data.course}-${data.term}`,
    }
    if (data.type === "personal") {
      form = {
        ...form,
        "subscriber-type": "person",
        "signuper0[name]": data.first_name,
        "signuper0[surname]": data.last_name,
        "signuper0[email]": data.email_address,
        address: data.address,
        city: data.city,
        postcode: data.postal_code,
        phone: data.phone,
        agreement: !!data.agreement[0],
        "signuper-count": "1",
      }
    } else {
      form = {
        ...form,
        "subscriber-type": "company",
        "requester-email": data.email_address,
        "requester-surname": data.last_name,
        "requester-name": data.first_name,
        "company-email": data.company_email_address,
        phone: data.company_phone,
        "company-nip": data.company_id,
        "company-regon": "nie podano",
        postcode: data.company_postal_code,
        city: data.company_city,
        address: data.company_address,
        "company-name": data.company_name,
        "signuper-count": data.students.length,
        agreement: !!data.agreement[0],
        ...parseStudents(data.students),
      }
    }
    const serialized = utils.serialize(form)
    setName(`${data.first_name} ${data.last_name}`)
    setEmail(data.email_address)
    triggerGoal()
    return api
      .signUp(serialized)
      .then(response => {
        if (response.ok) {
          setSuccess(true)
          return
        }
        if (response.status === 400) {
          // TODO handle errors
        }
        return {
          [FORM_ERROR]: "Nie udało się wysłać zgłoszenia",
        }
      })
      .catch(() => ({
        [FORM_ERROR]: "Nie udało się wysłać zgłoszenia",
      }))
  }

  return (
    <Layout>
      <Seo title="Formularz zgłoszeniowy na kurs" />
      <Backoff>
        <Wrapper>
          {success && (
            <div>
              <HeroContainer>
                <HeroHeader>Zgłoszenie zostało wysłane</HeroHeader>
                <SuccessView>
                  <TextContainer>
                    <SuccessIconContainer>
                      <OkIcon />
                    </SuccessIconContainer>
                    <p>
                      Dziękujemy za wybranie usług naszej firmy. Wkrótce wyślemy
                      potwierdzenie na podany podczas rejestracji adres e-mail
                    </p>
                  </TextContainer>
                </SuccessView>
              </HeroContainer>
            </div>
          )}
          {!success && courseList && (
            <FinalForm
              onSubmit={submitForm}
              decorators={[calulator]}
              mutators={{
                ...arrayMutators,
              }}
              initialValues={initialValues}
              render={({
                handleSubmit,
                form: {
                  mutators: { push, remove },
                },
                submitting,
                submitError,
              }) => (
                <form onSubmit={handleSubmit}>
                  <HeroContainer>
                    <HeroHeader>Zapisz się na kurs</HeroHeader>
                  </HeroContainer>
                  {parsedUniqueCourseList && (
                    <>
                      <FormPanel header="Kurs">
                        <FormInputsInnerContainer>
                          <FieldContainer>
                            <Label htmlFor="course">Kurs</Label>
                            <Field
                              validate={validate.required}
                              name="course"
                              type="select"
                              render={({ input }) => (
                                <Select id="course" {...input}>
                                  {parsedUniqueCourseList.map(course => (
                                    <option key={course.id} value={course.id}>
                                      {course.name}
                                    </option>
                                  ))}
                                </Select>
                              )}
                            />
                          </FieldContainer>
                          <FieldContainer>
                            <Label htmlFor="term">Termin</Label>
                            <Field
                              validate={validate.required}
                              name="term"
                              type="select"
                              render={({ input }) => (
                                <Select id="term" {...input}>
                                  <FormSpy subscription={{ values: true }}>
                                    {({ values }) => {
                                      const filtered = courseList.filter(
                                        course => course._id === values.course
                                      )
                                      return filtered.map(course => (
                                        <option
                                          key={course.terms._id}
                                          value={course.terms._id}
                                        >
                                          {course.terms.weekdays
                                            .map(day => utils.getDayName(day))
                                            .join(" i ")}
                                          &nbsp;od{" "}
                                          {dayjs(course.terms.date_from).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </option>
                                      ))
                                    }}
                                  </FormSpy>
                                </Select>
                              )}
                            />
                          </FieldContainer>
                        </FormInputsInnerContainer>
                      </FormPanel>
                      <FormPanel header="Typ zapisu">
                        <fieldset>
                          <RadiosContainer>
                            <RadioContainer>
                              <Field
                                validate={validate.required}
                                name="type"
                                type="radio"
                                render={({ input, meta }) => (
                                  <RadioInput id="type_personal" {...input} />
                                )}
                                value="personal"
                              />
                              <RadioLabel htmlFor="type_personal">
                                <RadioLabelText>Osoba fizyczna</RadioLabelText>
                              </RadioLabel>
                            </RadioContainer>
                            <RadioContainer>
                              <Field
                                name="type"
                                type="radio"
                                render={({ input, meta }) => (
                                  <RadioInput id="type_company" {...input} />
                                )}
                                value="company"
                              />
                              <RadioLabel htmlFor="type_company">
                                <RadioLabelText>Firma</RadioLabelText>
                              </RadioLabel>
                            </RadioContainer>
                          </RadiosContainer>
                        </fieldset>
                      </FormPanel>
                      <Condition when="type" is="company">
                        <FormPanel header="Dane firmy">
                          <FormInputsInnerContainer>
                            <FieldContainer>
                              <Label htmlFor="company_name">Nazwa firmy</Label>
                              <Field
                                validate={validate.required}
                                name="company_name"
                                type="text"
                                id="company_name"
                                component={TextInput}
                              />
                            </FieldContainer>
                            <FieldContainer>
                              <Label htmlFor="company_id">NIP</Label>
                              <Field
                                validate={validate.required}
                                name="company_id"
                                type="text"
                                id="company_id"
                                component={TextInput}
                              />
                            </FieldContainer>
                            <AddressContainer>
                              <Label htmlFor="company_address">Adres</Label>
                              <Field
                                validate={validate.required}
                                name="company_address"
                                type="text"
                                id="company_address"
                                component={TextInput}
                              />
                            </AddressContainer>

                            <CityContainer>
                              <Label htmlFor="company_city">Miejscowość</Label>
                              <Field
                                validate={validate.required}
                                name="company_city"
                                type="text"
                                id="company_city"
                                component={TextInput}
                              />
                            </CityContainer>

                            <PostalCodeContainer>
                              <Label htmlFor="company_postal_code">
                                Kod pocztowy
                              </Label>
                              <Field
                                validate={validate.required}
                                name="company_postal_code"
                                type="text"
                                id="company_postal_code"
                                component={TextInput}
                              />
                            </PostalCodeContainer>

                            <FieldContainer>
                              <Label htmlFor="company_email_address">
                                Email
                              </Label>
                              <Field
                                validate={validate.required}
                                name="company_email_address"
                                type="email"
                                id="company_email_address"
                                component={TextInput}
                              />
                            </FieldContainer>

                            <FieldContainer>
                              <Label htmlFor="company_phone">
                                Numer telefonu
                              </Label>
                              <Field
                                validate={validate.required}
                                name="company_phone"
                                type="tel"
                                id="company_phone"
                                component={TextInput}
                              />
                            </FieldContainer>
                          </FormInputsInnerContainer>
                        </FormPanel>
                      </Condition>
                      <Condition when="type" is="company">
                        <FormPanel header="Dane zgłaszającego">
                          <FormInputsInnerContainer>
                            <FormUserGroup prefix="" includeDetails />
                          </FormInputsInnerContainer>
                        </FormPanel>
                      </Condition>
                      <Condition when="type" is="personal">
                        <FormPanel header="Dane uczestnika">
                          <FormInputsInnerContainer>
                            <FormUserGroup prefix="" includeDetails />
                          </FormInputsInnerContainer>
                        </FormPanel>
                      </Condition>
                      <Condition when="type" is="company">
                        <FieldArray name="students">
                          {({ fields }) =>
                            fields.map((name, index) => (
                              <FormPanel
                                cta={
                                  <ButtonContainer>
                                    {fields.length > 1 && (
                                      <Button
                                        tertiary
                                        onClick={() =>
                                          remove("students", index)
                                        }
                                      >
                                        Usuń uczestnika
                                      </Button>
                                    )}
                                    {index === fields.length - 1 &&
                                      fields.length < 5 && (
                                        <Button
                                          secondary
                                          onClick={() =>
                                            push("students", undefined)
                                          }
                                        >
                                          Dodaj kolejnego uczestnika
                                        </Button>
                                      )}
                                  </ButtonContainer>
                                }
                                header={`Dane ${index + 1}. uczestnika`}
                                key={index}
                              >
                                <FormInputsInnerContainer>
                                  <FormUserGroup
                                    excludePhone
                                    prefix={`${name}.`}
                                  />
                                </FormInputsInnerContainer>
                              </FormPanel>
                            ))
                          }
                        </FieldArray>
                      </Condition>
                      <FormPanel
                        header="Zgoda"
                        cta={
                          <ButtonContainer>
                            {submitError ? (
                              <SubmitError>{submitError}</SubmitError>
                            ) : null}
                            <Button
                              type="submit"
                              pending={submitting}
                              disabled={submitting}
                            >
                              {submitting ? "Wysyłanie..." : "Zapisz się"}
                            </Button>
                          </ButtonContainer>
                        }
                      >
                        <AgreementText>
                          Powyższy formularz ma formę umowy zawartej pomiędzy
                          osobą zgłaszającą a firmą szkoleniową. Po otrzymaniu
                          zgłoszenia, na podany e-mail zostanie do Państwa
                          przesłane potwierdzenie udziału w wybranym kursie
                          zawierające informacje organizacyjne oraz prośbę o
                          dokonanie płatności. Warunkiem uczestnictwa w naszych
                          szkoleniach jest dokonanie w ciągu trzech dni od daty
                          zgłoszenia wpłaty na konto w kwocie 300 zł, po
                          uprzednim otrzymaniu pisemnego potwierdzenia udziału w
                          szkoleniu. Pozostałą część opłaty należy uiścić
                          najpóźniej przed szkoleniem. Opłata za uczestnictwo
                          obejmuje wszelkie koszty szkolenia oraz materiałów. Po
                          otrzymaniu całości zapłaty za kurs zostanie wystawiony
                          rachunek za uczestnictwo w kursie i przesłany na adres
                          Zgłaszającego.
                        </AgreementText>

                        <div>
                          <AgreementContainer>
                            <CheckboxContainer>
                              <Field
                                validate={validate.required}
                                type="checkbox"
                                component={CheckbotInput}
                                name="agreement"
                                id="agreement"
                                value="akceptuje"
                              />
                            </CheckboxContainer>
                            <AgreementLabelContainer>
                              <Label htmlFor="agreement">
                                Zapoznałem się z{" "}
                                <AgreementLink
                                  target="_blank"
                                  href="/regulamin-szkolen"
                                >
                                  regulaminem szkoleń
                                </AgreementLink>{" "}
                                *
                              </Label>
                            </AgreementLabelContainer>
                          </AgreementContainer>
                        </div>
                      </FormPanel>
                    </>
                  )}
                </form>
              )}
            />
          )}
        </Wrapper>
      </Backoff>
    </Layout>
  )
}

export default Form
